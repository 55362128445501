<template>
  <div class="app-container">
    <div class="filter-container">
      <span>查询条件</span>
      <el-row>
        订单编号:
        <el-input
          size="small"
          style="width: 300px"
          v-model="listQuery.orderNo"
          clearable
        ></el-input>
      </el-row>
      <el-row>
        操作日期:
        <el-date-picker
          v-model="date"
          clearable
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
        >
        </el-date-picker>
      </el-row>
      <el-row>
        操作类型:
        <el-select
          v-model="listQuery.operateType"
          size="small"
          clearable
          placeholder="请选择"
        >
          <el-option label="下载订单" value="0"></el-option>
          <el-option label="推单" value="1"></el-option>
          <el-option label="更新物流单号" value="2"></el-option>
        </el-select>
      </el-row>
      <el-row>
        操作状态:
        <el-select
          v-model="listQuery.status"
          size="small"
          clearable
          placeholder="请选择"
        >
          <el-option label="失败" value="0"></el-option>
          <el-option label="成功" value="1"></el-option>
        </el-select>
      </el-row>
      <el-row>
        <el-button type="primary" @click="onClickSelectButton">查询</el-button>
      </el-row>
    </div>

    <el-table
      :key="0"
      v-loading="listLoading"
      :data="list"
      style="width: 100%; min-height: 500px"
    >
      <el-table-column label="日志批号" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.id }}</span>
        </template>
      </el-table-column>

      <el-table-column label="订单编号" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.orderNo }}</span>
        </template>
      </el-table-column>

     <el-table-column label="推单编号" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.pushOrderNo }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作时间" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.createdAt | formatDateTime }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作人" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.operator ? row.operator.name : '' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作类型" align="center" prop="setUpCalandar">
        <template slot-scope="{ row }">
          <span>{{ row.operateType | operateTypeFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作状态" align="center" prop="setUpCalandar">
        <template slot-scope="{ row }">
          <span>{{ row.status | statusFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作详情" align="center" prop="setUpCalandar">
        <template slot-scope="{ row }">
          <span>{{ row.detail }}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="totalCount > 0"
      :total="totalCount"
      :page.sync="listQuery._page"
      :limit.sync="listQuery._limit"
      @pagination="getOperationLogs"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import moment from "moment";
import { getOperationLogs } from "@/api/order";
export default {
  components: {
    Pagination,
  },
  filters: {
    operateTypeFilter(v) {
      switch (v) {
        case 0:
          return "下载订单";
        case 1:
          return "推单";
        case 2:
          return "更新物流单号";
        case 3: 
        return '海关调用接口';
      }
    },
    statusFilter(v) {
      switch (v) {
        case 0:
          return "失败";
        case 1:
          return "成功";
      }
    },
  },
  data() {
    return {
      date: "",
      list: [],
      listQuery: {
        _page: 0,
        _limit: 20,
        startAt: null,
        endAt: null,
        orderNo: null,
        status: null,
        startDateFrom: null,
        startDateTo: null,
        operateType: null,
      },
      listLoading: false,
      totalCount: 0,
    };
  },
  watch: {
    date: {
      handler(v) {
        if (v == null) {
          this.listQuery.startAt = null;
          this.listQuery.endAt = null;
        } else {
          this.listQuery.startAt = this.formatTime(v[0]);
          this.listQuery.endAt = this.formatTime(v[1]);
        }
      },
    },
  },
  created() {
    this.getOperationLogs();
  },
  methods: {
    onClickSelectButton() {
      this.getOperationLogs();
    },
    formatTime(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    getOperationLogs() {
      let query = {
        _page: this.listQuery._page > 0 ? this.listQuery._page - 1 : 0,
        _limit: this.listQuery._limit,
        startAt: this.listQuery.startAt ? this.listQuery.startAt : null,
        endAt: this.listQuery.endAt ? this.listQuery.endAt : null,
        orderNo: this.listQuery.orderNo != "" ? this.listQuery.orderNo : null,
        startDateFrom:
          this.listQuery.startDateFrom != ""
            ? this.listQuery.startDateFrom
            : null,
        startDateTo:
          this.listQuery.startDateTo != "" ? this.listQuery.startDateTo : null,
        operateType:
          this.listQuery.operateType != "" ? this.listQuery.operateType : null,
        status: this.listQuery.status != "" ? this.listQuery.status : null,
      };
      getOperationLogs(query).then((_) => {
        this.list = _.data;
        this.totalCount = Number(_.headers["x-total-count"]);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-container {
  font-size: 14px;
  div {
    margin-bottom: 5px;
  }
}
</style>
